// Core
import React, { useState, useRef } from "react"
import gsap from "gsap"
import { ScrollToPlugin } from "gsap/all"
import TransitionLink from "gatsby-plugin-transition-link"
import { isMobile } from "react-device-detect"
import { localiseString } from "../../utils/localiseString"

// Components
import Img from "gatsby-image"
import ScrollSection from "../locomotiveScroll/scrollSection"

// Style
import styles from "./watchNext.module.scss"
import Video from "../video"

gsap.registerPlugin(ScrollToPlugin)

const WatchNext = ({
  slug,
  title,
  seasonNumber,
  episodeNumber,
  heroPosterLoop,
  heroPosterImage,
}) => {
  const [videoIsPlaying, setVideoIsPlaying] = useState(false)
  const videoRef = useRef(null)
  const wrap = useRef()

  const toNext = () => {
    const header = document.getElementById("header"),
      wrapper = document.querySelector(".tl-wrapper-status--entered")

    wrapper.style.opacity = 0
    wrapper.style.pointerEvents = "none"
    if (header) header.setAttribute("scrolled", true)

    if (isMobile) {
      gsap.to(window, { duration: 1, scrollTo: { y: wrap.current } })
    } else {
      window.scroll.scrollTo(wrap.current)
    }
  }

  const showVideo = () => {
    setVideoIsPlaying(true)
    gsap.to(videoRef.current, {
      opacity: 1,
      duration: 0.3,
      delay: 0.1,
      ease: "power2.out",
    })
  }

  const hideVideo = () => {
    gsap.to(videoRef.current, {
      opacity: 0,
      duration: 0.7,
      ease: "power2.in",
      onComplete: () => {
        setVideoIsPlaying(false)
      },
    })
  }

  return (
    <ScrollSection>
      <div className={styles.wrap} ref={wrap}>
        <div className="cols flex flex--center">
          <div className="col m12 tp10">
            <TransitionLink
              className={styles.link}
              to={"/" + slug}
              onClick={toNext}
              onMouseEnter={showVideo}
              onMouseLeave={hideVideo}
              exit={{
                delay: 1,
                length: 0,
                zIndex: 2,
              }}
              entry={{
                delay: 0,
                length: 0,
                zIndex: 1,
              }}
            >
              <div className={styles.linkWrapper}>
                <Img
                  alt={heroPosterImage.description}
                  fluid={heroPosterImage.fluid}
                  style={{ position: "absolute", top: 0, left: 0 }}
                />

                {heroPosterLoop && (
                  <div className={styles.video} ref={videoRef}>
                    <Video
                      vidID={heroPosterLoop}
                      playState={videoIsPlaying}
                      autoplay
                      fill
                      stretch
                      loop
                      muted
                    />
                  </div>
                )}

                <div className={styles.content}>
                  <h3 className={styles.title}>{localiseString("Watch Next")}</h3>
                  <p className={styles.number}>
                    <strong>{localiseString("Season")} {seasonNumber}</strong> - {localiseString("EP")} {episodeNumber}
                  </p>
                  <h4 className={styles.name}>{title}</h4>
                </div>
              </div>
            </TransitionLink>
          </div>
        </div>
      </div>
    </ScrollSection>
  )
}

export default WatchNext
