// Core
import React, {useRef, useEffect} from "react"
import { isMobile } from "react-device-detect"
import { localiseString } from "../../utils/localiseString"

// Components
import ScrollSection from "../locomotiveScroll/scrollSection"
import CreditsItem from "../creditsItem"

// Style
import styles from "./credits.module.scss"

const Credits = ({ count, scrollEvent, contentColumn1, contentColumn2 }) => {
  const wrap = useRef()

  let itemState = false

  const itemOpen = () => {
    itemState = true
  }

  const itemClose = () => {
    itemState = false
  }

  useEffect(() => {
    setTimeout(() => {
      if (!isMobile && scrollEvent) {
        window.scroll.on('call', (value, way, obj) => {
          if (value === "credits") scrollEvent(way, count, obj)
        })
      }
    }, 200)
  }, [])

  return (
    <ScrollSection>
      <div
        id="ep-credits"
        className={styles.wrap}
        data-scroll
        data-scroll-call="credits"
        data-scroll-offset="45%, 45%"
        >
        <div
          className={styles.listWrap}
          ref={wrap}
          >
          <ul className={styles.list}>
            <CreditsItem
              handleOpen={itemOpen.bind(this)}
              handleClose={itemClose.bind(this)}
              heading={localiseString("Credits")}
              column1={contentColumn1}
              column2={contentColumn2}
              />
          </ul>
        </div>
      </div>
    </ScrollSection>
  )
}

export default Credits