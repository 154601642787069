// Core
import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { isMobile } from "react-device-detect"

// Queries
import episodeQuery from "./episodeQuery" // eslint-disable-line no-unused-vars

// Utils
import { blockSwitch } from "../utils/blockSwitch"
import { blockList } from "../utils/blockList"

// Components
import EpisodeMenu from "../components/episodeMenu"
import Hero from "../components/hero"
import WatchNext from "../components/watchNext"
import SEO from "../components/seo"
import Credits from "../components/credits"
import Footer from "../components/footer"
import { maskIn } from "../utils/masks"

const Episode = props => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuHidden, setMenuHidden] = useState(false)
  const [menuCurrent, setMenuCurrent] = useState(1)
  const [menuTimeout, setMenuTimeout] = useState(null)

  let header = useRef(null),
    epScrolled = useRef(false)

  useEffect(() => {
    header.current = document.getElementById("header")

    if (header.current) header.current.setAttribute("scrolled", false)
    if (!isMobile) window.scroll.update()

    maskIn()

    setMenuTimeout(
      setTimeout(() => {
        setMenuOpen(true)
      }, 100)
    )

    return () => {
      if (menuTimeout) clearTimeout(menuTimeout)
    }
  }, [])

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const showMenu = () => {
    setMenuOpen(true)
    setMenuHidden(false)
  }

  const hideMenu = () => {
    setMenuOpen(false)
    setMenuHidden(true)
  }

  const scrolledHero = way => {
    if (way === "exit" && !epScrolled.current) {
      epScrolled.current = true
      if (header.current) header.current.setAttribute("scrolled", epScrolled.current)
    } else if (way === "enter" && epScrolled.current) {
      epScrolled.current = false
      if (header.current) header.current.setAttribute("scrolled", epScrolled.current)
    }
  }

  const ep = props.data.contentfulEpisode
  const {
    slug,
    title,
    episodeNumber,
    seasonNumber,
    expired,
    mobileHeroPosterImage,
    desktopHeroPosterImage,
    heroPosterLoop,
    episodeVimeoLink,
    fallbackYouTubeUrl,
    nextEpisode,
    seoTitle,
    seoDescription,
    seoHeroPosterImage,
    scheduledDateString,
    episodeScheduler,
    videoRuntime,
  } = ep

  const isLive = episodeScheduler

  const episodeInfo = {
    title: title,
    number: episodeNumber,
  }

  const blockScrollEvent = (way, count, obj) => {
    if (way === "enter") {
      setMenuCurrent(count + 1)
      return
    }
  }

  const findCredits = blocks => {
    let credits = []

    if (blocks === null) {
      return credits
    }

    blocks.forEach(block => {
      if (block.__typename === "ContentfulCredits") {
        credits.push(<Credits key={block.contentful_id} {...block} />)
      }
    })

    return credits
  }

  const blocks = blockSwitch(ep.episodeBuilder, episodeInfo, blockScrollEvent)
  const credits = findCredits(ep.episodeBuilder)
  const list = blockList(ep.episodeBuilder)

  return (
    <React.Fragment>
      <SEO
        title={seoTitle}
        description={seoDescription ? seoDescription.seoDescription : null}
        image={seoHeroPosterImage}
        pathname={slug}
      />

      <Hero
        mobileImage={mobileHeroPosterImage}
        desktopImage={desktopHeroPosterImage}
        loop={heroPosterLoop}
        vimeo={episodeVimeoLink}
        externalLink={fallbackYouTubeUrl}
        scrolledEvent={scrolledHero}
        openMenu={showMenu}
        closeMenu={hideMenu}
        expired={expired}
        title={title}
        description={seoDescription}
        episodeNumber={episodeNumber}
        seasonNumber={seasonNumber}
        runtime={videoRuntime}
        scheduledDateString={scheduledDateString}
        scheduledDate={episodeScheduler}
        reveal
      />

      {isLive && (
        <>
          <EpisodeMenu
            toggleMenu={toggleMenu}
            open={menuOpen}
            hidden={menuHidden}
            list={list}
            current={menuCurrent}
            expired={expired}
          />

          {blocks}

          {nextEpisode && <WatchNext {...nextEpisode} />}

          {credits && credits}
        </>
      )}

      <Footer />
    </React.Fragment>
  )
}

export default Episode

export const pageQuery = graphql`
  query EpisodeBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulEpisode(slug: { eq: $slug }) {
      ...episodeQuery
    }
  }
`
