// Core
import React, { useState, useRef, useEffect } from "react"
import { gsap } from "gsap"
import { isBrowser } from "react-device-detect"

// Components
import JsonText from "../_jsonText"
import { SlideMaskText } from "../slideMaskText"

// Style
import styles from "./creditsItem.module.scss"

// Handle scroll height change
const updateScroll = () => isBrowser && window.scroll.update()

const collapsible = node => {
  const tl = gsap.timeline({
    defaults: {
      duration: window.innerWidth < 1024 ? 2 : 1,
      ease: "power1.inOut",
      onUpdate: () => updateScroll(),
    },
  })

  const animation = height =>
    tl
      .to(node, { height: height, willChange: "height" })
      .to(node, { clearProps: "willChange" }, "-=0.45")
      .pause()

  let open = () => animation("auto").play()
  let close = () => animation(0).play()

  return { open, close }
}

const CreditsItem = ({
  mouseEnter,
  handleOpen,
  handleClose,
  heading,
  column1,
  column2,
}) => {
  const [state, setState] = useState({
    initial: false,
    clicked: null,
  })

  let body = useRef(null)

  const handleClick = () => {
    if (state.initial === false) {
      handleOpen()
      setState({
        initial: null,
        clicked: true,
      })
    } else if (state.clicked === true) {
      handleClose()
      setState({
        clicked: !state.clicked,
      })
    } else if (state.clicked === false) {
      handleOpen()
      setState({
        clicked: !state.clicked,
      })
    }
  }

  useEffect(() => {
    if (state.clicked === false) {
      collapsible(body).close()
    } else if (
      state.clicked === true ||
      (state.clicked === true && state.initial === null)
    ) {
      collapsible(body).open()
    }
  }, [state])

  return (
    <li
      className={`${styles.item} ${state.clicked ? styles.open : ""} container`}
      onMouseEnter={mouseEnter}
      onClick={() => handleClick()}
      data-slidemasktext
    >
      <div className={styles.itemHeader}>
        <div className={styles.itemIcon}></div>
        <h4 className={styles.itemTitle}>
          <SlideMaskText text={heading} />
        </h4>
      </div>
      <div className={styles.content} ref={el => (body = el)}>
        <div className={`${styles.contentInner} cols`}>
          <div className={`${styles.contentCol} col m12 t6 d4`}>
            <JsonText {...column1} />
          </div>
          <div className={`${styles.contentCol} col m12 t6 d4`}>
            <JsonText {...column2} />
          </div>
        </div>
      </div>
    </li>
  )
}

export default CreditsItem
